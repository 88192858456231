import React from 'react';
import styled from 'styled-components';
import { Icon } from './homeStyledComponents';
import { colors } from '../styledComponents';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import InstaFeed from './InstaFeed';

const Container = styled.div`
  padding: 40px 0 0;
  background-color: ${colors.lightGrey};
`;

const P = styled.p`
  color: ${colors.navy};
  text-align: center;
  font-size: 18px;
`;

const Row = styled.div`
  text-align: center;
`;

const SocialNetworks = () => (
  <Container>
    <P>{"Ne perdez pas le fil, suivez toute l'actualité de Tilli sur facebook et instagram"}</P>
    <Row>
      <a
        target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/tilli.couture/"
      >
        <Icon src={facebook} alt="page facebook de Tilli un service de retouches à domicile" />
      </a>
      <a
        target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/tilli.couture/"
      >
        <Icon src={instagram} alt="page instagram de Tilli une retoucherie à domicile" />
      </a>
    </Row>
    <InstaFeed />
  </Container>
);

export default SocialNetworks;
